import * as Sentry from '@sentry/browser';

const CANCELLED_ERROR_MESSAGES = [
  'canceled',
  'cancelled',
  'abgebrochen',
  'annulleret',
  'annulé',
  'cancelado',
  'отменено',
  'geannuleerd',
  'avbruten',
  'annullato',
  '已取消',
  'visszavonva',
];

const isSentryReportingEnabled = (env) => {
  if (env === 'production') {
    return true;
  }

  if (env === 'testing') {
    // don't send events in feature branch pull request deployment
    // if you want to send events in feature branch pull request deployment
    // modify to return true instead
    if (/^pr\d+\.testing\.vivino\.com$/i.test(location.hostname)) {
      return false;
    }

    return true;
  }

  // don't send events in development mode
  return false;
};

const environment = window.__PRELOADED_ENV__;

Sentry.init({
  enabled: false,
  dsn: window.__SENTRY_DSN__,
  environment,
  blacklistUrls: [
    // Chrome extensions
    /^chrome:/i,
    // Firefox extensions
    /^moz-extension:/i,
    /translatoruser/i,
  ],
  beforeSend(event, hint) {
    if (hint?.originalException) {
      const { originalException, syntheticException } = hint;

      const { error, message } = originalException;
      const errorMessage = message || error || '';

      if (
        errorMessage.match(/Cannot modify readonly property: constructor/i) ||
        errorMessage.match(/getOwnPropertySymbols/i) ||
        errorMessage.match(/supportsEventPageXY/i) ||
        errorMessage.match(/requestAnimationFrame/i) ||
        errorMessage.match(/TypeError: Illegal invocation/i) ||
        errorMessage.match(/paramblur.replace/i) || // social links on Mobile Safari
        errorMessage.match(/find variable: mobiGetClick/i) ||
        errorMessage.match(/idpiframe_initialization_failed/i) ||
        errorMessage.match(/There was a problem connecting to Vivino/i) ||
        errorMessage.match(/This coupon has expired/i) || // This error is just people trying to use invalid coupon codes. The error is show to the user.
        errorMessage.match(/Unbekannter Gutscheincode/i) ||
        errorMessage.match(/no user_key provided and not logged in/i) // unauthenticated user trying to access a protected resourse (pbly the session has expired)
      ) {
        return null;
      }

      if (syntheticException) {
        const stackTrace = syntheticException.stack || syntheticException.message;
        if (
          stackTrace.match(/supportsEventPageXY/i) ||
          stackTrace.match(/requestAnimationFrame/i)
        ) {
          return null;
        }
      }

      if (
        errorMessage.match(/Cannot read property 'tagName' of null/i) ||
        errorMessage.match(/\$ is not defined/i) ||
        errorMessage.match(/jQuery is not defined/i) ||
        errorMessage.match(/is not a function/i)
      ) {
        event.level = 'info';
        event.fingerprint = ['website-slow'];
      }

      if (
        errorMessage.match(/find variable: UET/i) ||
        errorMessage.match(/UET is not defined/i) // Bing tracking is blocked. Seen in Brave Browser
      ) {
        event.level = 'info';
        event.fingerprint = ['adblocker'];
      }

      if (
        errorMessage.match(/The operation timed out/i) ||
        errorMessage.match(/Failed to fetch/i) ||
        errorMessage.match(/The operation was aborted/i) ||
        errorMessage.match(/There was a problem connecting to Vivino/i)
      ) {
        event.level = 'info';
        event.fingerprint = ['network-errors'];
      }

      if (errorMessage.match(/ReadTimeout/i) || errorMessage.match(/OpenTimeout/i)) {
        event.level = 'info';
        event.fingerprint = ['wrest-network-errors'];
      }

      if (errorMessage.match(/Unsupported content type/i)) {
        event.fingerprint = ['wrest-unsupported-content-type'];
      }

      if (errorMessage.match(/bad URI/i)) {
        event.level = 'info';
        event.fingerprint = ['invalid-URI'];
      }

      if (CANCELLED_ERROR_MESSAGES.includes(errorMessage.toLowerCase())) {
        event.level = 'info';
        event.fingerprint = ['cancelled-errors'];
      }

      if (errorMessage.match(/Loading CSS chunk/i)) {
        event.fingerprint = ['loading-css-pack-errors'];
      }

      if (errorMessage.match(/Loading chunk/i)) {
        event.fingerprint = ['loading-js-pack-errors'];
      }

      if (event.fingerprint) {
        event.tags = event.tags || {};
        event.tags.fingerprint = event.fingerprint[0];
      }
    }

    if (!isSentryReportingEnabled(environment)) {
      // eslint-disable-next-line no-console
      console.error(`[ERROR TRACKING] running in ${environment} mode`, event, hint);
      return null;
    }

    return event;
  },
});

{
  const scope = Sentry.getCurrentScope();
  if (window.__PRELOADED_SIGNED_IN_USER__) {
    scope.setUser({ id: window.__PRELOADED_SIGNED_IN_USER__.id });
  }

  scope.setTag('locale', window.__PRELOADED_LOCALE__);
  scope.setExtra('country', window.__PRELOADED_USER_COUNTRY_CODE__);
  scope.setExtra('locale', window.__PRELOADED_LOCALE__);
  scope.setExtra('ship_to_country', window.__PRELOADED_COUNTRY_CODE__);
  scope.setExtra('ship_to_state', window.__PRELOADED_STATE_CODE__);
  scope.setExtra('signed_in', !!window.__PRELOADED_SIGNED_IN_USER__);
  scope.setExtra('state', window.__PRELOADED_USER_STATE_CODE__);
}

if (Sentry.isInitialized() && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.log('SENTRY INITIALIZED');
}
